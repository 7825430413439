import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { GET, DELETE } from '../../../Helpers/fetch'
import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import EditTrainingModal from '../../Modals/TrainingsModal/EditTrainingsModal/EditTrainingsModal'
import Snackbar from '../../Snackbar/Snackbar'
import Modal from '@mui/material/Modal/Modal'

const TrainingsRegisterTable = () => {
    const [data, setData] = useState()
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editedRow, setEditedRow] = useState(0)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [actionsAnchor, setActionsAnchor] = useState(null)
    const [snackbarInfo, setSnackbarInfo] = useState({})

    const actionsIsOpen = Boolean(actionsAnchor)

    const actionsClickHandler = (event, index) => {
        setActionsAnchor(event.currentTarget)
        setEditedRow(index)
    }
    const actionsCloseHandler = () => {
        setActionsAnchor(null)
    }

    const editHandler = () => {
        actionsCloseHandler()
        setOpenEditModal(true)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const trainingId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/training/delete/' + trainingId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const { data: Items } = await GET('training/get')
            if (Items) {
                setData([...Items.data])
                setLoading(false)
            }
        }
        getData().catch(console.error)
        return () => {
            setData([])
        }
    }, [refreshData])

    const importedColumns = columns({
        data: data?.map(el => ({
            trainingId: el._id,
            fileName: el.fileName,
            videoLink: el.videoLink,
        })),
        actionsClickHandler,
        actionsAnchor,
        actionsCloseHandler,
        actionsIsOpen,
        deleteHandler,
        setOpenDeleteDialog,
        editHandler,
    })

    return (
        <>
            <DataTable
                title={'Lista szkoleń'}
                data={data}
                columns={importedColumns}
                loading={loading}
            />
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            {!loading && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                >
                    <DialogContent>
                        <EditTrainingModal
                            data={data[editedRow]}
                            setSnackbarInfo={setSnackbarInfo}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Czy na pewno usunąć dane szkolenie?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie szkolenia.
                        Czy na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TrainingsRegisterTable
