import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { GET } from '../../../Helpers/fetch'
import { useEffect, useState } from 'react'

const EmployeeTrainingsTable = () => {
    const [data, setData] = useState()
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)

    const refreshHandler = () => {
        setRefreshData(!refreshData)
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const { data: Items } = await GET('training/getForEmployee')
            if (Items) {
                setData([...Items.data])
                setLoading(false)
            }
        }
        getData().catch(console.error)
        return () => {
            setData([])
        }
    }, [refreshData])

    const importedColumns = columns({
        data: data?.map(el => ({
            trainingId: el._id,
            videoLink: el.videoLink,
            fileName: el.fileName,
        })),
        refreshHandler,
    })

    return (
        <>
            <DataTable
                title={'Moje szkolenia'}
                data={data}
                columns={importedColumns}
                loading={loading}
            />
        </>
    )
}

export default EmployeeTrainingsTable
