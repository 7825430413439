import Typography from '@mui/material/Typography/Typography'
import Box from '@mui/material/Box/Box'
import moment from 'moment'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { forwardRef, useState } from 'react'
import { PATCH, POST } from '../../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const TrainingsDetails = forwardRef(({ data, refreshData }, ref) => {
    const {
        name,
        isCompleted,
        completeDate,
        expiryDate,
        _id,
        documentId,
        trainingDate,
    } = data
    const [loading, setLoading] = useState(false)
    const [completeLoading, setCompleteLoading] = useState(false)

    const completeTrainingHandler = async () => {
        setCompleteLoading(true)
        await POST('training/complete', {
            trainingId: _id,
            documentId: documentId,
        })
            .then(() => {
                setCompleteLoading(false)
                refreshData()
            })
            .catch(err => {
                setCompleteLoading(false)
                console.log(err)
            })
    }

    const renewTrainingHandler = () => {
        setLoading(true)
        const renew = async () => {
            await PATCH('training/renew', {
                trainingId: _id,
                documentId: documentId,
            })
        }

        renew()
            .then(() => {
                setLoading(false)
                refreshData()
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    return (
        <Box sx={style} ref={ref}>
            <Typography variant='h5'>{name}</Typography>
            <Typography>
                Czy ukończono: {isCompleted ? 'Tak' : 'Nie'}
            </Typography>
            <Typography>
                Data szkolenia:{' '}
                {trainingDate
                    ? moment(trainingDate).format('DD-MM-YYYY')
                    : 'Brak'}
            </Typography>
            <Typography>
                Data ukończenia:{' '}
                {completeDate
                    ? moment(completeDate).format('DD-MM-YYYY')
                    : 'Nie ukończono'}
            </Typography>
            <Typography>
                Data wygaśnięcia:{' '}
                {expiryDate ? moment(expiryDate).format('DD-MM-YYYY') : 'Brak'}
            </Typography>
            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'right',
                }}
            >
                <LoadingButton
                    onClick={completeTrainingHandler}
                    loading={completeLoading}
                    disabled={isCompleted}
                    variant='contained'
                    sx={{ mr: 2 }}
                >
                    Potwierdź
                </LoadingButton>
                <LoadingButton
                    onClick={renewTrainingHandler}
                    loading={loading}
                    disabled={!isCompleted}
                    variant='contained'
                >
                    Wyślij ponownie
                </LoadingButton>
            </Box>
        </Box>
    )
})

export default TrainingsDetails
