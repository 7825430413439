import { useContext, useState } from 'react'
import TokenContext from '../../store/token-context'
import logo from '../../assets/images/logo_5.png'

//icons
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import SchoolIcon from '@mui/icons-material/SchoolOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
// import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'

//mui
import { styled } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import SideMenuButton from './SideMenuButton/SideMenuButton'
import Box from '@mui/material/Box/Box'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}))

const SideMenu = ({ open }) => {
    const [trainingsOpen, setTrainingsOpen] = useState(false)

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant='persistent'
            anchor='left'
            open={open}
        >
            <DrawerHeader>
                <Box sx={{ pt: '5px', pb: '5px', height: '54px' }}>
                    <img src={logo} height={'100%'} alt={'Aplikacja Rodo.pl'} />
                </Box>
            </DrawerHeader>
            <Divider />
            <List>
                <SideMenuButton
                    to='pulpit'
                    icon={<DashboardIcon sx={{ color: 'text.white' }} />}
                    text='Pulpit'
                />
                {/* <SideMenuButton
                    to='moje-szkolenia'
                    icon={<SchoolIcon sx={{ color: 'text.white' }} />}
                    text='Moje szkolenia'
                /> */}

                <ListItemButton
                    onClick={() => setTrainingsOpen(!trainingsOpen)}
                >
                    <ListItemIcon>
                        <MenuBookOutlinedIcon sx={{ color: 'text.white' }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ fontSize: '0.9em' }}
                        primary='Szkolenia'
                    />
                    {trainingsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={trainingsOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <SideMenuButton
                            to='szkolenia-pracownikow'
                            icon={
                                <ChevronRightIcon
                                    sx={{ color: 'text.white' }}
                                />
                            }
                            text='Szkolenia pracowników'
                            nested
                        />
                        <SideMenuButton
                            to='lista-szkolen'
                            icon={
                                <ChevronRightIcon
                                    sx={{ color: 'text.white' }}
                                />
                            }
                            text='Lista szkoleń'
                            nested
                        />
                    </List>
                </Collapse>

                <SideMenuButton
                    to='pracownicy'
                    icon={<GroupOutlinedIcon sx={{ color: 'text.white' }} />}
                    text='Pracownicy'
                />

                <SideMenuButton
                    to='systemy'
                    icon={<BadgeOutlinedIcon sx={{ color: 'text.white' }} />}
                    text='Systemy'
                />
            </List>
            <Divider />
        </Drawer>
    )
}

export default SideMenu
