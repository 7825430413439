import { useState, useContext } from 'react'
import TokenContext from '../../store/token-context'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import Logo from '../../assets/svg/rodopl-logo.svg'
import BackgroundImage from '../../assets/svg/prism.svg'
import { POST } from '../../Helpers/fetch'

function Copyright(props) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link color='inherit' href='#'>
                View
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                },
            },
        },
    },
})

export default function SignIn() {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')

    const tokenCtx = useContext(TokenContext)

    const loginHandler = async event => {
        setLoading(true)
        event.preventDefault()
        const { status, data } = await POST('auth/login', {
            email: email,
            password: password,
        }).catch(err => err.response)
        if (status === 200) {
            tokenCtx.login(data.token)
        }
        setLoading(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Box sx={{ height: 80 }}>
                        <img
                            style={{ height: '100%' }}
                            src={Logo}
                            alt={'Logo'}
                        />
                    </Box>
                    <Typography component='h1' variant='h5'>
                        Zaloguj się
                    </Typography>
                    <Box
                        component='form'
                        onSubmit={event => loginHandler(event)}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            label='Email'
                            name='email'
                            value={email}
                            autoComplete='email'
                            autoFocus
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Hasło'
                            type='password'
                            id='password'
                            value={password}
                            autoComplete='current-password'
                            onChange={e => setPassword(e.target.value)}
                        />
                        <LoadingButton
                            type='submit'
                            fullWidth
                            loading={loading}
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Zaloguj
                        </LoadingButton>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    )
}
