import { useState, useContext } from 'react'
import TokenContext from '../../store/token-context'

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person'

const UserMenu = () => {
    const [anchorElUser, setAnchorElUser] = useState(null)
    const { logout } = useContext(TokenContext)

    const handleOpenUserMenu = event => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <Box>
            <Tooltip title='Użytkownik'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: 'primary.main' }} alt='Admin'>
                        <PersonIcon />
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={logout}>
                    <Typography textAlign='center'>Wyloguj</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default UserMenu
