import { Chip, Tooltip } from '@mui/material'
import moment from 'moment'

const columns = ({ selectTraining }) => [
    {
        name: 'employeeName',
        label: 'Pracownik',
    },
    {
        name: 'trainings',
        label: 'Szkolenia',
        options: {
            sort: false,

            customBodyRender: (value, tableMeta) => {
                const data = value.map((el, index) => {
                    let color = 'default'
                    if (!el.isCompleted) {
                        color = 'alert'
                    } else if (
                        moment(el.expiryDate).toDate() < moment().toDate()
                    ) {
                        color = 'error'
                    } else if (
                        moment(el.expiryDate).toDate() <
                        moment().add(1, 'M').toDate()
                    ) {
                        color = 'warning'
                    }

                    return (
                        <Tooltip title='Kliknij po szczegóły'>
                            <Chip
                                key={index}
                                color={color}
                                onClick={() =>
                                    selectTraining(tableMeta.rowIndex, index)
                                }
                                sx={{ mr: 1, mb: 1 }}
                                label={el.name}
                            />
                        </Tooltip>
                    )
                })
                return data
            },
        },
    },
]

export default columns
