import { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import TokenContext from './store/token-context'
import './App.css'

import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from '@mui/material/styles'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import 'moment/locale/pl'

import Layout from './containers/layout/Layout'
import SignIn from './containers/SignIn/SignIn'

moment.locale('pl')

const colors = createTheme({
    palette: {
        alert: {
            light: '#ffef62',
            main: '#ffeb3b',
            dark: '#b2a429',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        primary: {
            main: '#10403B',
        },
        secondary: {
            main: '#ffffff',
        },
        text: {
            primary: '#000000',
            white: '#ffffff',
        },
    },
})

let theme = createTheme(colors, {
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: colors.palette.primary.main,
                    color: colors.palette.text.white,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: 'small',
            },
        },
    },
})

theme = responsiveFontSizes(theme)

function App() {
    const { isLoggedIn } = useContext(TokenContext)
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
                <div className='App'>
                    <Routes>
                        {!isLoggedIn && (
                            <Route path='/signin' element={<SignIn />} />
                        )}
                        {isLoggedIn && (
                            <Route path='/app/*' element={<Layout />} />
                        )}
                        {!isLoggedIn && (
                            <Route
                                path='*'
                                element={<Navigate to='/signin' />}
                            />
                        )}
                        {isLoggedIn && (
                            <Route path='*' element={<Navigate to='/app' />} />
                        )}
                    </Routes>
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

export default App
