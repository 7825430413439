import FormControl from '@mui/material/FormControl/FormControl'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import { Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import moment from 'moment/moment'
import Input from '@mui/material/Input/Input'
import Chip from '@mui/material/Chip/Chip'
import Typography from '@mui/material/Typography/Typography'
import IconButton from '@mui/material/IconButton/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu/Menu'

const quatra = {
    admins: 'Administratorzy',
    bhp: 'BHP',
    boz: 'BOZ',
    controll: 'Controll',
    controll2: 'Controll 2',
    controll2Tdz: 'Controll 2 + TDZ',
    invoicesMarketing: 'FakturyMARKETING',
    finance: 'Finanse',
    ga: 'GA',
    inventory: 'Inwentaryzacja',
    lawFirm: 'Kancelaria',
    lawFirmUsers: 'Kancelaria Użytkownicy',
    checkout: 'KASA',
    coordinators: 'Koordynatorzy',
    accounting: 'KSIEGOWOSC',
    accountingTdz: 'KSIEGOWOSC + TDZ',
    storage: 'Magazyn',
    storageRo: 'Magazyn (RO)',
    storageDekr: 'MagazynDekr',
    flightOperations: 'OPERACJE LOTNICZE',
    hrOperator: 'Operator Kadr - Płac',
    workCardOperator: 'Operator Kart Pracy',
    pkzp: 'PKZP',
    sale: 'SPRZEDAŻ',
    resources: 'SRODKI',
    stAdministration: 'ST - Administracja',
    stRecords: 'ST - Ewidencja',
    orders: 'Zamowienia',
    lawFirmOou: 'Kancelaria OOU',
    parking: 'Parking',
    stInventory: 'ST - Inwentaryzacja',
    allPaisi: 'Wszystko (PAISI)',
    na: 'Nie dotyczy',
}

const columns = props => [
    {
        name: 'number',
        label: 'LP',
        options: {
            filter: false,
            customBodyRender: (value, tableMeta) => {
                let rowIndex = Number(tableMeta.rowIndex) + 1
                return <span>{rowIndex}</span>
            },
        },
    },
    {
        name: 'employeeName',
        label: 'Pracownik',
    },
    {
        name: 'quatraMax',
        label: 'Quatra Max',
        options: {
            customBodyRender: (value, tableMeta) => {
                if (!!value) {
                    const trueKeys = Object.keys(value).filter(
                        k => value[k].isSet
                    )
                    const array = trueKeys.map(el => (
                        <Chip sx={{ mr: 1, mb: 1 }} label={value[el].name} />
                    ))

                    if (array.length > 3) {
                        return (
                            <>
                                {array[0]} {array[1]} {array[2]}
                                <Typography>+{array.length - 3}</Typography>
                            </>
                        )
                    }

                    return array
                }
            },
            filter: true,
            filterType: 'custom',
            sort: false,
            filterOptions: {
                logic: (value, filters) => {
                    if (!!value) {
                        const trueKeys = Object.keys(value).filter(
                            k => value[k].isSet
                        )
                        const arr = trueKeys.map(el => value[el].name)
                        if (filters.length)
                            return !filters.every(r => arr.includes(r))
                        return false
                    }
                },
                display: (filterList, onChange, index, column) => {
                    const optionValues = Object.values(quatra)

                    const renderItem =
                        column.filterOptions && column.filterOptions.renderValue
                            ? column.filterOptions.renderValue
                            : v => v

                    return (
                        <FormControl variant='standard'>
                            <InputLabel htmlFor={column.name}>
                                {column.label}
                            </InputLabel>
                            <Select
                                multiple
                                value={filterList[index] || []}
                                renderValue={selected =>
                                    selected.map(renderItem).join(', ')
                                }
                                name={column.name}
                                input={
                                    <Input
                                        name={column.name}
                                        id={column.name}
                                    />
                                }
                                onChange={event => {
                                    filterList[index] = event.target.value
                                    onChange(filterList[index], index, column)
                                }}
                            >
                                {optionValues.map(item => (
                                    <MenuItem key={item} value={item}>
                                        <Checkbox
                                            color='primary'
                                            checked={
                                                filterList[index].indexOf(
                                                    item
                                                ) > -1
                                            }
                                        />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                },
            },
        },
    },
    {
        name: 'fenix',
        label: 'Fenix',
        options: {
            customBodyRender: value => {
                if (value === 'yes') {
                    return 'Tak'
                } else if (value === 'no') {
                    return 'Nie'
                } else {
                    return 'Nie dotyczy'
                }
            },
        },
    },
    {
        name: 'fis',
        label: 'FIS',
        options: {
            customBodyRender: value => {
                if (value === 'yes') {
                    return 'Tak'
                } else if (value === 'no') {
                    return 'Nie'
                } else {
                    return 'Nie dotyczy'
                }
            },
        },
    },
    {
        name: 'aviatis',
        label: 'AVIATIS',
        options: {
            customBodyRender: value => {
                if (value === 'yes') {
                    return 'Tak'
                } else if (value === 'no') {
                    return 'Nie'
                } else {
                    return 'Nie dotyczy'
                }
            },
        },
    },
    {
        name: 'bhs',
        label: 'BHS',
        options: {
            customBodyRender: value => {
                if (value === 'yes') {
                    return 'Tak'
                } else if (value === 'no') {
                    return 'Nie'
                } else {
                    return 'Nie dotyczy'
                }
            },
        },
    },
    {
        name: 'cardBoardingSystem',
        label: 'System kart boardingowych',
        options: {
            customBodyRender: value => {
                if (value === 'yes') {
                    return 'Tak'
                } else if (value === 'no') {
                    return 'Nie'
                } else {
                    return 'Nie dotyczy'
                }
            },
        },
    },
    {
        name: 'hadTraining',
        label: 'Ukończone szkolenie',
        options: {
            customBodyRender: value => (value ? 'Tak' : 'Nie'),
            display: false,
        },
    },
    // {
    //     name: 'authorizations',
    //     label: 'Nadane dostępy',
    //     options: {
    //         filter: true,
    //         filterType: 'custom',
    //         sort: false,
    //         filterOptions: {
    //             logic: (value, filters) => {
    //                 if (!!value) {
    //                     const arr = Object.values(value).map(
    //                         val => val['authName']
    //                     )
    //                     if (filters.length)
    //                         return !filters.every(r => arr.includes(r))
    //                     return false
    //                 }
    //             },
    //             display: (filterList, onChange, index, column) => {
    //                 const optionValues = [
    //                     'Moduł Kadr',
    //                     'Moduł Księgowość',
    //                     'Moduł CRM',
    //                     'Moduł Środki Trwałe',
    //                     'Moduł Przelewy',
    //                     'Moduł Kasa/Bank',
    //                     'Tylko podgląd',
    //                 ]
    //                 return (
    //                     <FormControl variant={'standard'}>
    //                         <InputLabel id='filter-label'>
    //                             Nadane Upoważnienia
    //                         </InputLabel>
    //                         <Select
    //                             labelId={'filter-label'}
    //                             multiple
    //                             value={filterList[index]}
    //                             renderValue={selected => selected.join(', ')}
    //                             onChange={event => {
    //                                 filterList[index] = event.target.value
    //                                 onChange(filterList[index], index, column)
    //                             }}
    //                         >
    //                             {optionValues.map(item => (
    //                                 <MenuItem key={item} value={item}>
    //                                     <Checkbox
    //                                         color='primary'
    //                                         checked={
    //                                             filterList[index].indexOf(
    //                                                 item
    //                                             ) > -1
    //                                         }
    //                                     />
    //                                     <ListItemText primary={item} />
    //                                 </MenuItem>
    //                             ))}
    //                         </Select>
    //                     </FormControl>
    //                 )
    //             },
    //         },

    //         customBodyRender: (value, tableMeta) => {
    //             if (!!value) {
    //                 return tableMeta.rowData[2].map((val, key) => {
    //                     let color = 'default'
    //                     let expiryDate = tableMeta.rowData[3]
    //                     if (!expiryDate) {
    //                         color = 'default'
    //                     } else if (
    //                         moment(expiryDate).toDate() < moment().toDate()
    //                     ) {
    //                         color = 'error'
    //                     } else if (
    //                         moment(expiryDate).toDate() <
    //                         moment().add(1, 'M').toDate()
    //                     ) {
    //                         color = 'warning'
    //                     }

    //                     let title =
    //                         'Data wygaśnięcia: ' +
    //                         (expiryDate
    //                             ? moment(expiryDate).format('LL')
    //                             : 'Do odwołania')

    //                     return (
    //                         <Tooltip key={key} title={title}>
    //                             <Chip
    //                                 sx={{ mr: 1, mb: 1 }}
    //                                 color={color}
    //                                 label={val.authName}
    //                             />
    //                         </Tooltip>
    //                     )
    //                 })
    //             }
    //         },
    //     },
    // },
    {
        name: 'additionDate',
        label: 'Data dodania',
        options: {
            customBodyRender: value =>
                value ? (
                    <Typography>
                        {moment(value).format('DD.MM.YYYY')}
                    </Typography>
                ) : null,
            display: false,
        },
    },
    { name: 'addedBy', label: 'Dodane przez', options: { display: false } },
    {
        name: 'acl',
        label: 'ACL',
        options: {
            display: false,
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionsAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            <MenuItem onClick={props.editHandler}>
                                Edytuj
                            </MenuItem>
                            <MenuItem onClick={props.archiveHandler}>
                                Archiwizuj
                            </MenuItem>
                            <MenuItem onClick={props.confirmHandler}>
                                Potwierdź
                            </MenuItem>
                            <MenuItem
                                onClick={() => props.setOpenDeleteDialog(true)}
                            >
                                Usuń
                            </MenuItem>
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
