import IconButton from '@mui/material/IconButton/IconButton'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box/Box'
import { GET_WITH_DOWNLOAD } from '../../../Helpers/fetch'
import fileDownload from 'js-file-download'
import { MenuItem, Tooltip } from '@mui/material'
import Menu from '@mui/material/Menu/Menu'
import Link from '@mui/material/Link/Link'

const downloadFile = async trainingId => {
    await GET_WITH_DOWNLOAD(`/training/download/${trainingId}`).then(res =>
        fileDownload(res.data, 'Szkolenie.pdf')
    )
}

const columns = props => [
    {
        name: 'number',
        label: 'LP',
        options: {
            sort: false,
            filter: false,
            customBodyRender: (value, tableMeta) => {
                let rowIndex = Number(tableMeta.rowIndex) + 1
                return <span>{rowIndex}</span>
            },
        },
    },
    { name: 'name', label: 'Nazwa szkolenia' },
    { name: 'description', label: 'Opis' },
    {
        name: 'isOnline',
        label: 'Typ',
        options: {
            customBodyRender: value => {
                return value ? 'Online' : 'Stacjonarnie'
            },
        },
    },
    {
        name: 'materials',
        label: 'Materiały',
        options: {
            customBodyRenderLite: rowIndex => {
                const { videoLink, fileName } = props.data[rowIndex]
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {fileName && (
                            <Tooltip title='Pobierz szkolenie'>
                                <IconButton
                                    onClick={() =>
                                        downloadFile(
                                            props.data[rowIndex].trainingId
                                        )
                                    }
                                >
                                    <SimCardDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {videoLink && (
                            <Tooltip title='Materiały wideo'>
                                <IconButton target='_blank' href={videoLink}>
                                    <OndemandVideoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )
            },
        },
    },
    {
        name: 'link',
        label: 'Test',
        options: {
            customBodyRender: value =>
                value ? (
                    <Tooltip title={value}>
                        <Link
                            fontWeight={'bold'}
                            color={'primary.main'}
                            href={value}
                            rel='noreferrer'
                        >
                            Test
                        </Link>
                    </Tooltip>
                ) : (
                    'Brak'
                ),
        },
    },
    {
        name: 'trainer',
        label: 'Prowadzący',
        options: {
            customBodyRender: value => (value ? value : 'Brak'),
        },
    },
    { name: 'validityPeriod.number', label: 'Okres ważności szkolenia' },
    {
        name: 'validityPeriod.unit',
        label: 'Jednostka ważności szkolenia',
        options: {
            customBodyRender: value => {
                switch (value) {
                    case 'year':
                        return 'Rok'
                    case 'month':
                        return 'Miesiąc'
                    case 'day':
                        return 'Dzień'
                    default:
                        return null
                }
            },
        },
    },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionsAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            <MenuItem onClick={props.editHandler}>
                                Edytuj
                            </MenuItem>
                            <MenuItem
                                onClick={() => props.setOpenDeleteDialog(true)}
                            >
                                Usuń
                            </MenuItem>
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
