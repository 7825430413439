import axios from 'axios'

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`

export const GET = url => {
    return axios.get(`${url}`)
}

export const GET_WITH_DOWNLOAD = url => {
    return axios.get(`${url}`, { responseType: 'blob' })
}

export const POST_WITH_DOWNLOAD = (url, data) => {
    return axios.post(
        `${url}`,
        { data },
        {
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob',
        }
    )
}

export const POST = (url, data) => {
    return axios.post(
        `${url}`,
        { data },
        { headers: { 'Content-Type': 'application/json' } }
    )
}
export const POST_WITH_FILE = (url, data) => {
    return axios.post(`${url}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

export const PUT = (url, data) => {
    return axios.put(
        `${url}`,
        { data },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export const PATCH = (url, data) => {
    return axios.patch(
        `${url}`,
        { data },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export const DELETE = url => {
    return axios.delete(`${url}`)
}
