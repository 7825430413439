import { useContext } from 'react'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import UserMenu from '../UserMenu/UserMenu'
import MenuIcon from '@mui/icons-material/Menu'
import SupportIcon from '@mui/icons-material/Support'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Link } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import TokenContext from '../../store/token-context'

const drawerWidth = 240

const Header = ({ changeOpen, open, logout }) => {
    const tokenData = useContext(TokenContext)

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: prop => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }))

    return (
        <AppBar color='default' elevation={1} position='fixed' open={open}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <IconButton
                        size='large'
                        edge='start'
                        sx={{ mr: 2 }}
                        onClick={changeOpen}
                    >
                        <MenuIcon sx={{ color: 'primary.main' }} />
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ pr: '20px' }}>
                        {/* <IconButton
                            target='_blank'
                            href='https://rodoapp.atlassian.net/servicedesk/customer/portal/1'
                            size='large'
                            edge='start'
                        >
                            <SupportIcon sx={{ color: 'primary.main' }} />
                        </IconButton>
                        <IconButton
                            target='_blank'
                            href='https://rodoapp.atlassian.net/servicedesk/customer/portal/2'
                            size='large'
                            edge='start'
                        >
                            <HelpOutlineOutlinedIcon
                                sx={{ color: 'primary.main' }}
                            />
                        </IconButton> */}
                        {tokenData.data.role === 'admin' && (
                            <IconButton
                                component={Link}
                                to='ustawienia'
                                size='large'
                                color='inherit'
                            >
                                <SettingsIcon sx={{ color: 'primary.main' }} />
                            </IconButton>
                        )}
                    </Box>
                    <UserMenu logout={logout} />
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header
