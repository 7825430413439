import IconButton from '@mui/material/IconButton/IconButton'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import { GET_WITH_DOWNLOAD, POST } from '../../../Helpers/fetch'
import fileDownload from 'js-file-download'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button/Button'
import Box from '@mui/material/Box/Box'
import Link from '@mui/material/Link/Link'
import moment from 'moment'

const downloadFile = trainingId => {
    GET_WITH_DOWNLOAD(`/training/download/${trainingId}`).then(res =>
        fileDownload(res.data, 'Szkolenie.pdf')
    )
}

const getCertficate = trainingId => {
    GET_WITH_DOWNLOAD('/training/getCertificate/' + trainingId).then(res => {
        var fileURL = URL.createObjectURL(res.data)
        window.open(fileURL)
        // fileDownload(res.data, 'Certyfikat.pdf')
    })
}

const columns = props => [
    {
        name: 'number',
        label: 'LP',
        options: {
            sort: false,
            filter: false,
            customBodyRender: (value, tableMeta) => {
                let rowIndex = Number(tableMeta.rowIndex) + 1
                return <span>{rowIndex}</span>
            },
        },
    },
    { name: 'name', label: 'Nazwa szkolenia' },
    { name: 'description', label: 'Opis' },
    {
        name: 'isOnline',
        label: 'Typ',
        options: {
            customBodyRender: value => {
                return value ? 'Online' : 'Stacjonarnie'
            },
        },
    },
    {
        name: 'materials',
        label: 'Materiały',
        options: {
            customBodyRenderLite: rowIndex => {
                const { videoLink, fileName } = props.data[rowIndex]
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {fileName && (
                            <Tooltip title='Pobierz szkolenie'>
                                <IconButton
                                    onClick={() =>
                                        downloadFile(
                                            props.data[rowIndex].trainingId
                                        )
                                    }
                                >
                                    <SimCardDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {videoLink && (
                            <Tooltip title='Materiały wideo'>
                                <IconButton target='_blank' href={videoLink}>
                                    <OndemandVideoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )
            },
        },
    },
    {
        name: 'link',
        label: 'Test',
        options: {
            customBodyRender: value =>
                value ? (
                    <Tooltip title={value}>
                        <Link
                            fontWeight={'bold'}
                            color={'primary.main'}
                            href={value}
                            rel='noreferrer'
                        >
                            Test
                        </Link>
                    </Tooltip>
                ) : (
                    'Brak'
                ),
        },
    },
    {
        name: 'trainer',
        label: 'Prowadzący',
        options: {
            customBodyRender: value => (value ? value : 'Brak'),
        },
    },
    {
        name: 'trainingDate',
        label: 'Data szkolenia',
        options: {
            customBodyRender: (value, tableMeta) => {
                if (value) {
                    return moment(value).format('DD.MM.YYYY')
                } else {
                    return 'Brak'
                }
            },
        },
    },
    {
        name: 'completeDate',
        label: 'Data ukończenia',
        options: {
            customBodyRender: (value, tableMeta) => {
                const lastCol = tableMeta.rowData.length - 1 //isCompleted column number
                if (value) {
                    return moment(value).format('DD.MM.YYYY')
                } else if (tableMeta.rowData[lastCol]) {
                    return moment().format('DD.MM.YYYY')
                } else {
                    return 'Nie ukończono'
                }
            },
        },
    },
    {
        name: 'isCompleted',
        label: ' ',
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                const completeTraining = async () => {
                    await POST('training/complete', {
                        trainingId: props.data[tableMeta.rowIndex].trainingId,
                    }).catch(err => console.log(err))
                    updateValue(true)
                }

                const onClickHandler = () =>
                    value
                        ? getCertficate(
                              props.data[tableMeta.rowIndex].trainingId
                          )
                        : completeTraining()

                return (
                    <Button onClick={onClickHandler}>
                        {value ? 'Certyfikat' : 'Potwierdź'}
                    </Button>
                )
            },
        },
    },
]

export default columns
