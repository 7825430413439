import { forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    AutocompleteElement,
    CheckboxElement,
    RadioButtonGroup,
    FormContainer,
    TextFieldElement,
} from 'react-hook-form-mui'
import { parseError } from '../../../Helpers/formValidation'
import AddEmployeeModal from '../AddEmployeeModal/AddEmployeeModal'
import CustomAutocomplete from '../../CustomMuiComponents/CustomAutocomplete'

//mui
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid/Grid'
// import Input from '@mui/material/Input/Input'
import { InputLabel } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Button from '@mui/material/Button/Button'
import Modal from '@mui/material/Modal/Modal'
import { GET, POST } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddEmployeeSystemsModal = forwardRef(
    ({ close, setSnackbarInfo, refreshData }, ref) => {
        const formContext = useForm()

        const [employeeOpen, setEmployeeOpen] = useState(false)

        const [employeeList, setEmployeeList] = useState([])
        const [acl, setAcl] = useState([])

        const [loading, setLoading] = useState(false)
        const [loadingEmployees, setLoadingEmployees] = useState(false)
        const [, setAclLoading] = useState(false)

        const [refreshAcl, setRefreshAcl] = useState(false)

        const addAclHandler = data => {
            const addAcl = async () => {
                await POST('employeeSystems/addAcl', { name: data })
            }
            addAcl().catch(err => console.log(err))
            setRefreshAcl(!refreshAcl)
        }

        const addEmployeeSystemsHandler = data => {
            setLoading(true)
            data.acl = data.acl.map(el => el.id ?? el)
            const addAuthorizations = async () => {
                await POST('employeeSystems/add', {
                    ...data,
                    employeeName: data.employeeName.id,
                })
            }
            addAuthorizations()
                .then(() => {
                    setSnackbarInfo({
                        open: true,
                        type: 'success',
                        message: 'Dodano upoważnienie',
                    })
                    setLoading(false)
                    close(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    setSnackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Błąd: Nie dodano upoważnienia',
                    })
                })
        }

        const getAcl = async () => {
            const { data: Items } = await GET('employeeSystems/getAcl')
            if (Items) {
                const acl = Items.data.map(el => ({
                    id: el.name,
                    title: el.name,
                }))
                setAcl([...acl])
                setAclLoading(false)
            }
        }

        const getEmployees = async () => {
            setLoadingEmployees(true)
            const { data: Items } = await GET('employee/get')
            const employees = Items.data.map(el => ({
                label: `${el.firstName} ${el.lastName} (${el._id})`,
                id: el._id,
            }))
            setEmployeeList([...employees])
            setLoadingEmployees(false)
        }

        useEffect(() => {
            getEmployees().catch(err => console.error(err))
            getAcl().catch(err => console.error(err))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const quatra = [
            { label: 'Administratorzy', name: 'admins' },
            { label: 'BHP', name: 'bhp' },
            { label: 'BOZ', name: 'boz' },
            { label: 'Controll', name: 'controll' },
            { label: 'Controll2', name: 'controll2' },
            { label: 'Controll2 + TDZ', name: 'controll2Tdz' },
            { label: 'FakturyMARKETING', name: 'invoicesMarketing' },
            { label: 'Finanse', name: 'finance' },
            { label: 'GA', name: 'ga' },
            { label: 'Inwentaryzacja', name: 'inventory' },
            { label: 'Kancelaria', name: 'lawFirm' },
            {
                label: 'Kancelaria - użytkownicy',
                name: 'lawFirmUsers',
            },
            { label: 'KASA', name: 'checkout' },
            { label: 'Koordynatorzy', name: 'coordinators' },
            { label: 'KSIEGOWOSC', name: 'accounting' },
            { label: 'KSIEGOWOSC + TDZ', name: 'accountingTdz' },
            { label: 'Magazyn', name: 'storage' },
            { label: 'Magazyn (RO)', name: 'storageRo' },
            { label: 'MagazynDekr', name: 'storageDekr' },
            { label: 'OPERACJE LOTNICZE', name: 'flightOperations' },
            { label: 'Operator Kadr - Płac', name: 'hrOperator' },
            { label: 'Operator Kart Pracy', name: 'workCardOperator' },
            { label: 'PKZP', name: 'pkzp' },
            { label: 'SPRZEDAŻ', name: 'sale' },
            { label: 'SRODKI', name: 'resources' },
            { label: 'ST - Administracja', name: 'stAdministration' },
            { label: 'ST - Ewidencja', name: 'stRecords' },
            { label: 'Zamowienia', name: 'orders' },
            { label: 'Kancelaria OOU', name: 'lawFirmOou' },
            { label: 'Parking', name: 'parking' },
            { label: 'ST - Inwentaryzacja', name: 'stInventory' },
            { label: 'Wszystko (PAISI)', name: 'allPaisi' },
            { label: 'Nie dotyczy', name: 'na' },
        ]

        const CustomRadioGroup = ({ label, name }) => {
            return (
                <>
                    <Grid item xs={12}>
                        <InputLabel>{label}</InputLabel>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <RadioButtonGroup
                            name={name}
                            parseError={parseError}
                            required
                            options={[
                                {
                                    id: 'yes',
                                    label: 'Tak',
                                },
                                {
                                    id: 'no',
                                    label: 'Nie',
                                },
                                {
                                    id: 'na',
                                    label: 'Nie dotyczy',
                                },
                            ]}
                            row
                        />
                    </Grid>
                </>
            )
        }

        return (
            <Box ref={ref} sx={style}>
                <Typography gutterBottom variant='h6'>
                    Nadaj dostępy systemowe pracownikowi
                </Typography>
                <Divider />
                <Modal
                    open={employeeOpen}
                    onClose={() => setEmployeeOpen(false)}
                >
                    <AddEmployeeModal
                        setSnackbarInfo={setSnackbarInfo}
                        close={() => setEmployeeOpen(false)}
                    />
                </Modal>
                <FormContainer
                    formContext={formContext}
                    onSuccess={addEmployeeSystemsHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={4}>
                            <AutocompleteElement
                                rules={{ required: 'Pole jest wymagane' }}
                                loading={loadingEmployees}
                                size={'small'}
                                label={'Pracownik'}
                                fullWidth
                                name={'employeeName'}
                                options={employeeList}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <Button
                                onClick={() => setEmployeeOpen(true)}
                                variant={'text'}
                            >
                                Dodaj pracownika
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <TextFieldElement
                                fullWidth
                                multiline
                                maxRows={5}
                                name={'implementer'}
                                label='Realizujący'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                fullWidth
                                multiline
                                maxRows={5}
                                name={'requestor'}
                                label='Wnioskujący'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>1. Quatra Max</InputLabel>
                            <Divider />
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent='space-evenly'
                        >
                            {quatra.map((el, index) => (
                                <Grid key={el + index} item xs={4}>
                                    <CheckboxElement
                                        label={el.label}
                                        name={`quatraMax.${el.name}.isSet`}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <CustomRadioGroup label='2. Fenix' name='fenix' />
                        <CustomRadioGroup label='3. FIS' name='fis' />
                        <CustomRadioGroup label='4. AVIATIS' name='aviatis' />
                        <CustomRadioGroup
                            label='5. PC Corsac, PC Alert'
                            name='pcCorsacAlert'
                        />
                        <CustomRadioGroup label='6. BHS' name='bhs' />
                        <CustomRadioGroup
                            label='7. System kart boardingowych'
                            name='cardBoardingSystem'
                        />

                        <Grid item xs={12}>
                            <InputLabel>8. ACL</InputLabel>
                            <Divider />
                        </Grid>

                        <Grid item xs={6}>
                            <CustomAutocomplete
                                name={'acl'}
                                addToList={addAclHandler}
                                dialogTitle='Dodaj nową ACL'
                                label='ACL'
                                multiple
                                options={acl}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>
                                9. PAISI (dla systemu innego niż MAXeBizens)
                            </InputLabel>
                            <Divider />
                        </Grid>

                        <Grid item xs={6}>
                            <TextFieldElement
                                fullWidth
                                name={'otherPaisi'}
                                label='Nazwa systemu'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Uzasadnienie</InputLabel>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldElement
                                fullWidth
                                multiline
                                maxRows={5}
                                name={'justification'}
                                label='Treść uzasadnienia'
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default AddEmployeeSystemsModal
