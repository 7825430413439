import MUIDataTable from 'mui-datatables'
import Box from '@mui/material/Box'
import GeneratePdfButton from './GeneratePdfButton/GeneratePdfButton'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'

const DataTable = ({ columns, data, title, pdf, loading, passedOptions }) => {
    const options = {
        ...passedOptions,
        draggableColumns: {
            enabled: true,
        },
        download: false,
        responsive: 'vertical',
        elevation: 1,
        enableNestedDataAccess: '.',
        filterType: 'multiselect',
        fixedHeader: true,
        rowHover: true,
        tableBodyMaxHeight: '60vh',
        print: false,
        setRowProps: (row, dataIndex) => {
            return {
                // Set backgroundColor of archive auths
                style: {
                    backgroundColor: data[dataIndex].isArchive
                        ? '#ffe3e3'
                        : data[dataIndex].isUnconfirmed
                        ? '#fff8e3'
                        : '#ffffff',
                },
            }
        },
        customToolbarSelect: (selectedRows, displayData) => {
            const rowsToGenerate = selectedRows.data.map(
                id =>
                    data.flatMap((el, index) => (index === id.index ? el : []))
                // displayData.flatMap(el =>
                //     el.dataIndex === id.dataIndex ? el.data : []
                // )
            )
            if (pdf) {
                return <GeneratePdfButton rows={rowsToGenerate} />
            }
        },
        //Translation
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress />
                ) : (
                    'Nie znaleziono pasujących rekordów'
                ),
                toolTip: 'Sortuj',
            },
            pagination: {
                next: 'Następna strona',
                previous: 'Poprzednia strona',
                rowsPerPage: 'Wierszy na stronie',
                displayRows: 'z',
            },
            toolbar: {
                search: 'Szukaj',
                downloadCsv: 'Pobierz CSV',
                viewColumns: 'Widoczne kolumny',
                filterTable: 'Filtruj',
            },
            filter: {
                all: 'Wszystko',
                title: 'FILTRY',
            },
            viewColumns: {
                title: 'Pokaż kolumny',
                titleAria: 'Pokaż/ukryj kolumny',
            },
            selectedRows: {
                text: 'wybranych wierszy',
                delete: 'Usuń',
                deleteAria: 'Usuń wybrane wiersze',
            },
        },
    }

    return (
        <Box sx={{ mt: 3 }}>
            <MUIDataTable
                columns={columns}
                data={data}
                options={options}
                title={title}
            />
        </Box>
    )
}

export default DataTable
