import Typography from '@mui/material/Typography/Typography'
import moment from 'moment/moment'
import IconButton from '@mui/material/IconButton/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'

const columns = props => [
    {
        name: 'number',
        label: 'LP',
        options: {
            filter: false,
            customBodyRender: (value, tableMeta) => {
                let rowIndex = Number(tableMeta.rowIndex) + 1
                return <span>{rowIndex}</span>
            },
        },
    },
    { name: 'firstName', label: 'Imię' },
    { name: 'lastName', label: 'Nazwisko' },
    {
        name: 'hireDate',
        label: 'Data zatrudnienia',
        options: {
            customBodyRender: value => (
                <Typography>{moment(value).format('DD.MM.YYYY')}</Typography>
            ),
        },
    },
    { name: 'position', label: 'Stanowisko' },
    { name: 'organizationUnits',
      label: 'Jednostka Organizacyjna',
        options: {
            customBodyRender: function (value) {
                return value.join(', ')
            }
        }
    },
    { name: 'departmens',
      label: 'Dział',
        options: {
            customBodyRender: function (value) {
                return value.join(', ')
            }
        }
    },
    { name: 'contractType', label: 'Umowa' },
    {
        name: 'actions',
        label: 'Akcje',
        options: {
            viewColumns: false,
            filter: false,
            customBodyRenderLite: rowIndex => {
                return (
                    <>
                        <IconButton
                            onClick={e =>
                                props.actionsClickHandler(e, rowIndex)
                            }
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id='basic-menu'
                            anchorEl={props.actionsAnchor}
                            open={props.actionsIsOpen}
                            onClose={props.actionsCloseHandler}
                            elevation={1}
                        >
                            <MenuItem onClick={props.editHandler}>
                                Edytuj
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    props.setOpenFireEmployeeDialog(true)
                                }
                            >
                                Zwolnij
                            </MenuItem>
                            <MenuItem
                                onClick={() => props.setOpenDeleteDialog(true)}
                            >
                                Usuń
                            </MenuItem>
                        </Menu>
                    </>
                )
            },
        },
    },
]

export default columns
