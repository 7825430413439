import { useRef } from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import { CSVLink } from 'react-csv'

const CustomToolbar = ({ onClick, download, data, headers }) => {
    const csvLink = useRef()

    return (
        <>
            <Tooltip title={'Dodaj'}>
                <IconButton onClick={onClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            {download && (
                <Tooltip title={'Pobierz CSV'}>
                    <IconButton onClick={() => csvLink.current.link.click()}>
                        <DownloadIcon />
                        <CSVLink
                            style={{ color: 'black' }}
                            data={data}
                            filename={'export.csv'}
                            headers={headers}
                            ref={csvLink}
                        />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )
}

export default CustomToolbar
