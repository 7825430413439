import { useState } from 'react'

import Grid from '@mui/material/Grid/Grid'

import AddEmployeeSystemsModal from '../../components/Modals/AddEmployeeSystemsModal/AddEmployeeSystemsModal'
import AddEmployeeModal from '../../components/Modals/AddEmployeeModal/AddEmployeeModal'
import AddUserModal from '../../components/Modals/AddUserModal/AddUserModal'
import Snackbar from '../../components/Snackbar/Snackbar'
import Modal from '@mui/material/Modal/Modal'
import CustomCard from '../../components/CustomCard/CustomCard'
import AddTraining from '../../components/Modals/TrainingsModal/AddTraining/AddTraining'

const Panel = () => {
    const [employeeOpen, setEmployeeOpen] = useState(false)
    const [employeeAuthorizationsOpen, setEmployeeAuthorizationsOpen] =
        useState(false)
    const [trainingsOpen, setTrainingsOpen] = useState(false)
    const [userOpen, setUserOpen] = useState(false)
    const [employeeData, setEmployeeData] = useState({})
    const [snackbarInfo, setSnackbarInfo] = useState({})

    const closeEmployee = (isCreateAccount, employeeData) => {
        setEmployeeOpen(false)
        if (isCreateAccount) {
            setEmployeeData(employeeData)
            setUserOpen(true)
        }
    }

    return (
        <Grid sx={{ mt: 1 }} container spacing={4}>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <Grid container item xs={12} spacing={2}>
                <Modal open={userOpen} onClose={() => setUserOpen(false)}>
                    <AddUserModal
                        close={() => setUserOpen(false)}
                        employeeData={employeeData}
                    />
                </Modal>

                <Grid item xs={3}>
                    <CustomCard
                        title={'Nowy pracownik'}
                        text={'Dodawanie pracownika'}
                        open={employeeOpen}
                        onClose={() => setEmployeeOpen(false)}
                        onClick={() => setEmployeeOpen(true)}
                    >
                        <AddEmployeeModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={closeEmployee}
                        />
                    </CustomCard>
                </Grid>

                <Grid item xs={3}>
                    <CustomCard
                        title={'Dodaj upoważnienie'}
                        text={'Dodaj dane dotyczące upoważnienia pracownika'}
                        open={employeeAuthorizationsOpen}
                        onClose={() => setEmployeeAuthorizationsOpen(false)}
                        onClick={() => setEmployeeAuthorizationsOpen(true)}
                    >
                        <AddEmployeeSystemsModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={() => setEmployeeAuthorizationsOpen(false)}
                        />
                    </CustomCard>
                </Grid>

                <Grid item xs={3}>
                    <CustomCard
                        title={'Szkolenie'}
                        text={'Dodawanie nowego szkolenia'}
                        open={trainingsOpen}
                        onClose={() => setTrainingsOpen(false)}
                        onClick={() => setTrainingsOpen(true)}
                    >
                        <AddTraining
                            setSnackbarInfo={setSnackbarInfo}
                            close={() => setTrainingsOpen(false)}
                        />
                    </CustomCard>
                </Grid>
            </Grid>
            {/* <Grid item xs={4}>
                <Card
                    sx={{
                        height: 220,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CardContent sx={{ flexGrow: 1, textAlign: 'left' }}>
                        <Typography gutterBottom variant='h5' component='h2'>
                            View_11_Lawica
                        </Typography>
                        <Divider />
                        <Typography sx={{ mt: 3 }}>Wersja testowa</Typography>
                    </CardContent>
                </Card>
            </Grid> */}
        </Grid>
    )
}

export default Panel
