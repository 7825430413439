import { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import Header from '../../components/Header/Header'
import SideMenu from '../../components/SideMenu/SideMenu'
import Content from '../../components/Content/Content'
import Settings from '../../components/Settings/Settings'
import Panel from '../Panel/Panel'
import SystemsTable from '../../components/Tables/SystemsTable/SystemsTable'
import EmployeeTable from '../../components/Tables/EmployeeTable/EmployeeTable'
import TrainingsRegisterTable from '../../components/Tables/TrainingsRegisterTable/TrainingsRegisterTable'
import EmployeeTrainingsTable from '../../components/Tables/EmployeeTrainingsTable/EmployeeTrainingsTable'
import AllEmployeesTrainingsTable from '../../components/Tables/AllEmployeesTrainingsTable/AllEmployeesTrainingsTable'

const Layout = ({ logout }) => {
    const [open, setOpen] = useState(true)

    const changeOpen = () => {
        setOpen(!open)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Header logout={logout} changeOpen={changeOpen} open={open} />
            <SideMenu changeOpen={changeOpen} open={open} />
            <Content open={open}>
                <Routes>
                    <Route path='pracownicy' element={<EmployeeTable />} />
                    <Route
                        path='lista-szkolen'
                        element={<TrainingsRegisterTable />}
                    />
                    <Route
                        path='moje-szkolenia'
                        element={<EmployeeTrainingsTable />}
                    />
                    <Route
                        path='szkolenia-pracownikow'
                        element={<AllEmployeesTrainingsTable />}
                    />
                    <Route path='systemy' element={<SystemsTable />} />
                    <Route path='pulpit' element={<Panel />} />
                    <Route path='ustawienia' element={<Settings />} />
                    <Route path='*' element={<Navigate to='pulpit' />} />
                </Routes>
            </Content>
        </Box>
    )
}

export default Layout
