import { forwardRef, useState } from 'react'
import {
    FormContainer,
    SwitchElement,
    TextFieldElement,
    SelectElement,
} from 'react-hook-form-mui'
import { POST_WITH_FILE, POST } from '../../../../Helpers/fetch'
import {
    numberValidation,
    parseError,
} from '../../../../Helpers/formValidation'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm } from 'react-hook-form'
import { Input, InputLabel } from '@mui/material'
import Button from '@mui/material/Button/Button'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Switch from '@mui/material/Switch/Switch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const EditTraining = forwardRef(({ close, setSnackbarInfo, data }, ref) => {
    const formContext = useForm({ defaultValues: data })
    const isOnline = formContext.watch('isOnline')

    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState({ name: data.fileName })
    const [isFileChanged, setIsFileChanged] = useState(false)
    const [ufn, setUfn] = useState(data.untilFurtherNotice) //UFN - Until further notice - "do odwołania"

    const changeUfnHandler = () => {
        setUfn(!ufn)
        formContext.resetField('validityPeriod.unit')
        formContext.resetField('validityPeriod.number')
    }

    const editTrainingHandler = data => {
        setLoading(true)

        const formData = new FormData()
        formData.append(
            'data',
            JSON.stringify({ ...data, untilFurtherNotice: ufn })
        )
        formData.append('file', file)

        const editCandidate = async () => {
            if (isFileChanged) {
                await POST_WITH_FILE('training/editWithFile', formData)
            } else {
                await POST('training/edit', {
                    ...data,
                    untilFurtherNotice: ufn,
                })
            }
        }
        editCandidate()
            .then(() => {
                setSnackbarInfo({
                    open: true,
                    type: 'success',
                    message: 'Edytowane szkolenie',
                })
                setLoading(false)
                close()
            })
            .catch(() => {
                setLoading(false)
                setSnackbarInfo({
                    open: true,
                    type: 'error',
                    message: 'Błąd: Nie edytowano szkolenia',
                })
            })
    }

    const uploadFileHandler = event => {
        const fileData = event.target.files[0]
        setIsFileChanged(true)
        setFile(fileData)
    }

    return (
        <Box ref={ref} sx={style}>
            <Typography gutterBottom variant={'h5'}>
                Dodaj szkolenie
            </Typography>
            <Divider />
            <FormContainer
                formContext={formContext}
                onSuccess={editTrainingHandler}
            >
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldElement
                            parseError={parseError}
                            name={'name'}
                            autoFocus
                            required
                            fullWidth
                            label={'Nazwa'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldElement
                            parseError={parseError}
                            name={'trainer'}
                            fullWidth
                            label={'Prowadzący'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldElement
                            parseError={parseError}
                            name={'description'}
                            multiline
                            required
                            fullWidth
                            label={'Opis'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Okres ważności szkolenia</InputLabel>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldElement
                            parseError={parseError}
                            required={!ufn}
                            disabled={ufn}
                            validation={{ ...numberValidation, disabled: ufn }}
                            name={'validityPeriod.number'}
                            fullWidth
                            label={'Liczba'}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectElement
                            parseError={parseError}
                            name={'validityPeriod.unit'}
                            required={!ufn}
                            disabled={ufn}
                            validation={{
                                disabled: ufn,
                            }}
                            fullWidth
                            label='Jednostka'
                            options={[
                                { id: 'year', label: 'Rok' },
                                { id: 'month', label: 'Miesiąc' },
                                { id: 'day', label: 'Dzień' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={ufn}
                                    onChange={changeUfnHandler}
                                />
                            }
                            label='Do odwołania'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Materiały</InputLabel>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <SwitchElement
                            name={'isOnline'}
                            onChange={event => console.log(event)}
                            label={isOnline ? 'Online' : 'Stacjonarnie'}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextFieldElement
                            parseError={parseError}
                            name={'link'}
                            disabled={!isOnline}
                            fullWidth
                            label={'Link do testu'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldElement
                            parseError={parseError}
                            name={'videoLink'}
                            fullWidth
                            label={'Link do materiału wideo'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='center'
                            border={1}
                            borderRadius={'4px'}
                            borderColor='#c4c4c4'
                        >
                            <label htmlFor='contained-button-file'>
                                <Input
                                    sx={{ display: 'none' }}
                                    onChange={uploadFileHandler}
                                    accept='.pdf'
                                    id='contained-button-file'
                                    multiple
                                    type='file'
                                />

                                <Button
                                    sx={{
                                        borderRight: 1,
                                        borderRadius: '4px',
                                        borderColor: '#c4c4c4',
                                        pr: 2,
                                        pl: 2,
                                    }}
                                    variant='text'
                                    component='span'
                                >
                                    Dodaj PDF
                                </Button>
                            </label>
                            <Typography
                                noWrap
                                sx={{
                                    ml: 1,
                                    color: 'text.secondary',
                                    width: '50%',
                                }}
                            >
                                {file?.name}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                    }}
                >
                    <LoadingButton
                        loading={loading}
                        type={'submit'}
                        variant='contained'
                    >
                        Dodaj
                    </LoadingButton>
                </Box>
            </FormContainer>
        </Box>
    )
})

export default EditTraining
