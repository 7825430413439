import { useEffect, useState } from 'react'
import moment from 'moment'
import { DELETE, GET, PATCH } from '../../../Helpers/fetch'
import columns from './columns'

import AddEmployeeSystemsModal from '../../Modals/AddEmployeeSystemsModal/AddEmployeeSystemsModal'
import EditAuthorizationsModal from '../../Modals/EditAuthorizationsModal/EditAuthorizationsModal'
import DataTable from '../../DataTable/DataTable'

import Dialog from '@mui/material/Dialog/Dialog'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogContentText from '@mui/material/DialogContentText/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import Modal from '@mui/material/Modal/Modal'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TableCell from '@mui/material/TableCell/TableCell'
import TableRow from '@mui/material/TableRow/TableRow'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import Snackbar from '../../Snackbar/Snackbar'

const SystemsTable = () => {
    const [data, setData] = useState([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)
    const [editedRow, setEditedRow] = useState(0)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [actionsAnchor, setActionsAnchor] = useState(null)
    const [snackbarInfo, setSnackbarInfo] = useState({})

    const actionsIsOpen = Boolean(actionsAnchor)

    const checkValue = value => {
        if (value === 'yes') {
            return 'Tak'
        } else if (value === 'no') {
            return 'Nie'
        } else {
            return 'Nie dotyczy'
        }
    }

    const csvData = data.map(el => ({
        ...el,
        fenix: checkValue(el.fenix),
        fis: checkValue(el.fis),
        aviatis: checkValue(el.aviatis),
        pcCorsacAlert: checkValue(el.pcCorsacAlert),
        bhs: checkValue(el.bhs),
        cardBoardingSystem: checkValue(el.cardBoardingSystem),
        additionDate: moment(el.additionDate).format('DD-MM-YYYY'),
        quatraMax: Object.keys(el.quatraMax).flatMap(k =>
            el.quatraMax[k].isSet ? el.quatraMax[k].name : []
        ),
        hadTraining: el.hadTraining ? 'Tak' : 'Nie',
    }))

    const csvHeaders = [
        { label: 'Pracownik', key: 'employeeName' },
        { label: 'Quatra Max', key: 'quatraMax' },
        { label: 'Fenix', key: 'fenix' },
        { label: 'Fis', key: 'fis' },
        { label: 'Aviatis', key: 'aviatis' },
        { label: 'PC Corsac, PC Alert', key: 'pcCorsacAlert' },
        { label: 'BHS', key: 'bhs' },
        { label: 'System kart boardingowych', key: 'cardBoardingSystem' },
        { label: 'ACL', key: 'acl' },
        { label: 'PAISI', key: 'otherPaisi' },
        { label: 'Uzasadnienie', key: 'justification' },
        { label: 'Ukończone szkolenie', key: 'hadTraining' },
        { label: 'Data dodania', key: 'additionDate' },
    ]

    const actionsClickHandler = (event, index) => {
        setActionsAnchor(event.currentTarget)
        setEditedRow(index)
    }

    const actionsCloseHandler = () => {
        setActionsAnchor(null)
    }

    const addModalCloseHandler = refresh => {
        setOpenAddModal(false)
        if (refresh) setRefreshData(!refreshData)
    }

    const editHandler = () => {
        actionsCloseHandler()
        setOpenEditModal(true)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const documentId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('employeeSystems/delete/' + documentId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    const archiveHandler = () => {
        actionsCloseHandler()
        const documentId = data[editedRow]._id

        const archiveData = async () => {
            await PATCH('employeeSystems/archive/' + documentId)
        }
        archiveData()
            .then(() => {
                setRefreshData(!refreshData)
            })
            .catch(err => {
                console.error(err)
            })
    }
    const confirmHandler = () => {
        actionsCloseHandler()
        const documentId = data[editedRow]._id

        const confirmData = async () => {
            await PATCH('employeeSystems/confirm/' + documentId)
        }
        confirmData()
            .then(() => {
                setRefreshData(!refreshData)
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const { data: Items } = await GET('employeeSystems/get')
            if (Items) {
                setData([...Items.data])
                setLoading(false)
            }
        }
        getData().catch(console.error)
        return () => {
            setData([])
        }
    }, [refreshData])

    const importedColumns = columns({
        actionsClickHandler,
        actionsAnchor,
        actionsIsOpen,
        actionsCloseHandler,
        editHandler,
        archiveHandler,
        confirmHandler,
        setOpenDeleteDialog,
        data,
    })

    const options = {
        customToolbar: () => (
            <CustomToolbar
                onClick={() => setOpenAddModal(true)}
                download
                data={csvData}
                headers={csvHeaders}
            />
        ),
        expandableRowsHeader: false,
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1
            const currentRowData = data[rowMeta.dataIndex]
            return importedColumns
                .filter(col => col?.options?.display === false)
                .map((el, ix) => (
                    <TableRow key={el.label + rowMeta.dataIndex + ix}>
                        <TableCell colSpan={1}></TableCell>
                        <TableCell align='right' colSpan={2}>
                            {' '}
                            {el.label}
                        </TableCell>
                        <TableCell colSpan={colSpan}>
                            {' '}
                            {el?.options?.customBodyRender
                                ? el.options.customBodyRender(
                                      currentRowData[el.name]
                                  )
                                : Array.isArray(currentRowData[el.name])
                                ? currentRowData[el.name].map(el => `${el}, `)
                                : currentRowData[el.name]}
                        </TableCell>
                    </TableRow>
                ))
        },
    }

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <DataTable
                title={'Systemy'}
                data={data}
                pdf
                passedOptions={options}
                columns={importedColumns}
                loading={loading}
            />
            {!loading && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                >
                    <DialogContent>
                        <EditAuthorizationsModal
                            data={data[editedRow]}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenEditModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}
            {!loading && (
                <Modal open={openAddModal} onClose={addModalCloseHandler}>
                    <DialogContent>
                        <AddEmployeeSystemsModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={addModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno usunąć systemy tego pracownika?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie danych. Czy
                        na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDeleteDialog(false)}
                        autofocus
                    >
                        Anuluj
                    </Button>
                    <LoadingButton
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Potwierdź
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SystemsTable
