import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete/Autocomplete'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import Dialog from '@mui/material/Dialog/Dialog'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button/Button'

const filter = createFilterOptions()
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const CustomAutocomplete = props => {
    const { control } = useFormContext()
    const { append } = useFieldArray({ control, name: props.name })

    const [dialogValue, setDialogValue] = useState('')
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setDialogValue('')
        setOpen(false)
    }

    const handleSubmit = event => {
        event.preventDefault()
        props.addToList(dialogValue)
        if (props.multiple) {
            append({ id: dialogValue, title: dialogValue })
        }
        handleClose()
    }

    return (
        <>
            <Controller
                name={props.name}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        multiple={props.multiple}
                        onChange={(event, newValue, reason) => {
                            console.log(newValue)
                            if (
                                typeof newValue[newValue.length - 1] ===
                                    'string' &&
                                reason === 'createOption'
                            ) {
                                setTimeout(() => {
                                    setOpen(true)
                                    setDialogValue(
                                        newValue[newValue.length - 1]
                                    )
                                })
                            } else if (
                                newValue &&
                                newValue[newValue.length - 1]?.inputValue &&
                                reason === 'selectOption'
                            ) {
                                // Create a new value from the user input
                                setOpen(true)
                                setDialogValue(
                                    newValue[newValue.length - 1].inputValue
                                )
                            } else {
                                field.onChange(newValue)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params)

                            const { inputValue } = params
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                                option => inputValue === option.title
                            )
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    title: `Dodaj: "${inputValue}"`,
                                })
                            }

                            return filtered
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={props.options}
                        getOptionLabel={option => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue
                            }
                            // Regular option
                            return option.title
                        }}
                        isOptionEqualToValue={(option, value) => {
                            const optionTitle =
                                typeof option === 'string'
                                    ? option
                                    : option.title
                            const valueTitle =
                                typeof value === 'string' ? value : value.title
                            return optionTitle === valueTitle
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        freeSolo
                        renderInput={params => (
                            <TextField {...params} label={props.label} />
                        )}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        value={dialogValue}
                        onChange={event => setDialogValue(event.target.value)}
                        label='Nazwa'
                        type='text'
                        variant='standard'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Anuluj</Button>
                    <Button onClick={handleSubmit}>Dodaj</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomAutocomplete
