import { useEffect, useState } from 'react'
import DataTable from '../../DataTable/DataTable'
import columns from './columns'
import { GET } from '../../../Helpers/fetch'
import Modal from '@mui/material/Modal/Modal'
import TrainingsDetails from '../../Modals/TrainingsModal/TrainingsDetails/TrainingsDetails'

const AllEmployeesTrainingsTable = () => {
    const [data, setData] = useState([])
    const [refreshData, setRefreshData] = useState(false)
    const [openDetails, setOpenDetails] = useState(false)
    const [selectedRow, setSelectedRow] = useState(0)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [loading, setLoading] = useState(true)

    const refreshHandler = () => {
        setRefreshData(!refreshData)
        setOpenDetails(false)
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const { data: Items } = await GET('training/getForAllEmployees')
            if (Items) {
                setData([...Items.data])
                setLoading(false)
            }
        }
        getData().catch(console.error)
        return () => {
            setData([])
        }
    }, [refreshData])

    const selectTraining = (row, index) => {
        setSelectedRow(row)
        setSelectedIndex(index)
        setOpenDetails(true)
    }

    const importedColumns = columns({ selectTraining })

    const dataForDetails = !loading && {
        ...data[selectedRow].trainings[selectedIndex],
        documentId: data[selectedRow]._id,
    }

    return (
        <>
            <DataTable
                title={'Szkolenia pracowników'}
                data={data}
                columns={importedColumns}
                loading={loading}
            />
            {!loading && (
                <Modal open={openDetails} onClose={() => setOpenDetails(false)}>
                    <TrainingsDetails
                        refreshData={refreshHandler}
                        data={dataForDetails}
                    />
                </Modal>
            )}
        </>
    )
}

export default AllEmployeesTrainingsTable
