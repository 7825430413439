import { useState } from 'react'
import {
    FormContainer,
    MultiSelectElement,
    SelectElement,
    TextFieldElement,
} from 'react-hook-form-mui'

import { parseError } from '../../../Helpers/formValidation'
import { PUT } from '../../../Helpers/fetch'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import { forwardRef } from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const AddUserModal = forwardRef(
    ({ close, setSnackbarInfo, employeeData }, ref) => {
        const [loading, setLoading] = useState(false)

        const modulesList = [
            'Upoważnienia',
            'Rejestr Upoważnień',
            'Pracownicy',
            'Kandydaci',
        ]
        const rolesList = ['Moderator', 'Pracownik', 'Tylko odczyt']

        const addUserHandler = data => {
            setLoading(true)
            const addUser = async () => {
                await PUT('auth/signup', {
                    ...data,
                    employeeId: employeeData.employeeData.employeeId,
                })
            }
            addUser()
                .then(res => {
                    setLoading(false)
                    close()
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Wypełnij dane nowego użytkownika
                </Typography>
                <Divider />
                <FormContainer
                    defaultValues={employeeData.employeeData}
                    onSuccess={addUserHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'email'}
                                autoFocus
                                required
                                fullWidth
                                label={'Email'}
                                type={'email'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'password'}
                                required
                                fullWidth
                                label={'Hasło'}
                                type={'password'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'company'}
                                required
                                fullWidth
                                label={'Firma'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'firstName'}
                                required
                                fullWidth
                                label={'Imię'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'lastName'}
                                required
                                fullWidth
                                label={'Nazwisko'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectElement
                                parseError={parseError}
                                name={'role'}
                                required
                                fullWidth
                                label={'Rola'}
                                options={rolesList.map(el => ({
                                    id: el,
                                    label: el,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MultiSelectElement
                                parseError={parseError}
                                name={'moduleAccess'}
                                required
                                fullWidth
                                options={[...modulesList]}
                                label={'Moduły'}
                                showCheckbox
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant='contained'
                        >
                            Dodaj
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default AddUserModal
