import { Link } from 'react-router-dom'
//mui
import ListItemButton from '@mui/material/ListItemButton/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon'
import ListItemText from '@mui/material/ListItemText/ListItemText'

const SideMenuButton = ({ to, icon, text, nested = false }) => {
    return (
        <ListItemButton component={Link} to={to} sx={nested ? { pl: 4 } : {}}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ fontSize: '0.9em' }}
                primary={text}
            />
        </ListItemButton>
    )
}

export default SideMenuButton
