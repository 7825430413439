import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import AddUserModal from '../Modals/AddUserModal/AddUserModal'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
//icons
import AddIcon from '@mui/icons-material/Add'

//mui
import Box from '@mui/material/Box/Box'
import { CircularProgress, ListItemText, TextField } from '@mui/material'
import TabList from '@mui/lab/TabList/TabList'
import List from '@mui/material/List/List'
import ListItemButton from '@mui/material/ListItemButton/ListItemButton'
import Button from '@mui/material/Button/Button'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider/Divider'

//mui-lab
import { TabContext, TabPanel } from '@mui/lab'

import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Modal from '@mui/material/Modal/Modal'

const Settings = () => {
    const [value, setValue] = useState('1')
    const [email, setEmail] = useState()
    const [userOpen, setUserOpen] = useState(false)
    const [loading, setLoading] = useState()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const onEditorStateChange = editorState => {
        setEditorState(editorState)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const changeEmail = event => {
        setEmail(event.target.value)
    }

    useEffect(() => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/company/get`, {
            method: 'GET',
        })
            .then(results => {
                return results.json()
            })
            .then(data => {
                setEmail(data.data[0].email[0].emailAddress)
                setLoading(false)
            })
    }, [])

    return (
        <Box
            boxShadow={3}
            sx={{
                backgroundColor: '#ffffff',
                height: '80vh',
                textAlign: 'left',
            }}
        >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleChange}
                        aria-label='lab API tabs example'
                    >
                        <Tab label='Administarcja' value='1' />
                        <Tab label='Procesy' value='2' />
                        <Tab label='Retencja danych' />
                        <Tab label='Dostępy' value='4' />
                    </TabList>
                </Box>
                <TabPanel sx={{ flexDirection: 'column' }} value='1'>
                    {!loading ? (
                        <>
                            <Button
                                sx={{ m: 1 }}
                                color='error'
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={() => setUserOpen(true)}
                            >
                                Dodaj nowego użytkownika
                            </Button>
                            <Modal
                                open={userOpen}
                                onClose={() => setUserOpen(false)}
                                aria-labelledby='modal-modal-title'
                                aria-describedby='modal-modal-description'
                            >
                                <AddUserModal
                                    close={() => setUserOpen(false)}
                                />
                            </Modal>

                            <Grid container sx={{ width: 500 }}>
                                <Grid item sx={{ mb: 2, mt: 2 }} xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={'subtitle2'}>
                                        Adres IT
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {' '}
                                        Podaj domyślny adres mailowy do działu
                                        IT
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={{ m: 1 }}
                                        label='Adres IT'
                                        value={email}
                                        onChange={changeEmail}
                                        size={'small'}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item sx={{ mb: 2, mt: 2 }} xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={'subtitle2'}>
                                        Adres Ochrony
                                    </Typography>
                                    <Typography variant={'body2'}>
                                        {' '}
                                        Podaj domyślny adres mailowy do ochrony
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={{ m: 1 }}
                                        label='Adres ochrony'
                                        size={'small'}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item sx={{ mb: 2, mt: 2 }} xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </TabPanel>
                <TabPanel value='2'>
                    <Editor
                        editorState={editorState}
                        wrapperClassName='demo-editor'
                        editorClassName='demo-wrapper'
                        onEditorStateChange={onEditorStateChange}
                    />
                </TabPanel>
                <TabPanel value='4'>
                    <List>
                        <ListItemButton>
                            <ListItemText primary='Polityki' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Raporty' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Rejestry' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Sprawdzenia planowane' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Sprawozdania z audytu' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Uchwały i zarządzenia' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Upoważnienia' />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary='Zgody' />
                        </ListItemButton>
                    </List>
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default Settings
