import {
    parseError,
    phoneValidation,
    secondPhoneValidation,
} from '../../../Helpers/formValidation'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import { InputLabel } from '@mui/material'
import Divider from '@mui/material/Divider/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import { forwardRef, useEffect, useState } from 'react'
import CustomAutocomplete from '../../CustomMuiComponents/CustomAutocomplete'
import {
    AutocompleteElement,
    DatePickerElement,
    FormContainer,
    TextFieldElement,
    RadioButtonGroup,
} from 'react-hook-form-mui'
import { useForm } from 'react-hook-form'
import { POST, GET } from '../../../Helpers/fetch'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    maxHeight: '90%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const CustomRadioGroup = ({ label, name, onChange }) => {
    return (
        <>
            <Grid item xs={12}>
                <InputLabel>{label}</InputLabel>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <RadioButtonGroup
                    name={name}
                    parseError={parseError}
                    required
                    onChange={onChange}
                    options={[
                        {
                            id: 'yes',
                            label: 'Tak',
                        },
                        {
                            id: 'na',
                            label: 'Nie dotyczy',
                        },
                    ]}
                    row
                />
            </Grid>
        </>
    )
}

const EditEmployeeModal = forwardRef(
    ({ close, setSnackbarInfo, data, refreshData }, ref) => {
        const formContext = useForm({ defaultValues: data })

        const [buttonLoading, setButtonLoading] = useState(false)
        const [, setOrganizationUnitsLoading] = useState(false)
        const [organizationUnits, setOrganizationUnits] = useState([])
        const [refreshOrganizationUnits, setRefreshOrganizationUnits] =
            useState(false)
        const [groupEmailNameDisabled, setGroupEmailNameDisabled] = useState(
            data.groupEmail !== 'yes'
        )
        const [printerColorDisabled, setPrinterColorDisabled] = useState(
            data.printer !== 'yes'
        )
        const [, setDepartmentsLoading] = useState(false)
        const [departments, setDepartments] = useState([])
        const [refreshDepartments, setRefreshDepartments] = useState(false)

        const addOrganizationUnitsHandler = data => {
            const addOrganizationUnits = async () => {
                await POST('employee/addOrganizationUnits', { name: data })
            }
            addOrganizationUnits().catch(err => console.log(err))
            setRefreshOrganizationUnits(!refreshOrganizationUnits)
        }

        const addDepartmentsHandler = data => {
            const addDepartments = async () => {
                await POST('employee/addDepartments', { name: data })
            }
            addDepartments().catch(err => console.log(err))
            setRefreshDepartments(!refreshDepartments)
        }

        useEffect(() => {
            setOrganizationUnitsLoading(true)
            setDepartments(true)
            const getOrganizationUnits = async () => {
                const { data: Items } = await GET(
                    'employee/getOrganizationUnits'
                )
                if (Items) {
                    const organizationUnits = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setOrganizationUnits([...organizationUnits])
                    setOrganizationUnitsLoading(false)
                }
            }
            const getDepartments = async () => {
                const { data: Items } = await GET('employee/getDepartments')
                if (Items) {
                    const departments = Items.data.map(el => ({
                        id: el.name,
                        title: el.name,
                    }))
                    setDepartments([...departments])
                    setDepartmentsLoading(false)
                }
            }

            getOrganizationUnits().catch(console.error)
            getDepartments().catch(console.error)
            return () => {
                setOrganizationUnits([])
                setDepartments([])
            }
        }, [refreshOrganizationUnits, refreshDepartments])

        const addEmployeeHandler = data => {
            setButtonLoading(true)
            data.organizationUnits = data.organizationUnits.map(
                el => el.id ?? el
            )
            data.departmens = data.departmens.map(el => el.id ?? el)
            const addEmployee = async () => {
                await POST('employee/edit', data)
            }
            addEmployee()
                .then(() => {
                    setButtonLoading(false)
                    refreshData()
                    close()
                })
                .catch(err => {
                    console.error(err)
                    setButtonLoading(false)
                })
        }

        const changeGroupEmailNameHandler = value => {
            if (value === 'yes') {
                setGroupEmailNameDisabled(false)
            } else {
                setGroupEmailNameDisabled(true)
                formContext.resetField('groupEmailName')
            }
        }
        const changePrinterColorHandler = value => {
            if (value === 'yes') {
                setPrinterColorDisabled(false)
            } else {
                setPrinterColorDisabled(true)
                formContext.resetField('printerColor')
            }
        }

        return (
            <Box sx={style} ref={ref}>
                <Typography gutterBottom variant={'h5'}>
                    Wypełnij dane nowego pracownika
                </Typography>
                <Divider />
                <FormContainer
                    formContext={formContext}
                    onSuccess={addEmployeeHandler}
                >
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'firstName'}
                                autoFocus
                                required
                                fullWidth
                                label={'Imię'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'lastName'}
                                required
                                fullWidth
                                label={'Nazwisko'}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'position'}
                                required
                                fullWidth
                                label={'Stanowisko'}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <CustomAutocomplete
                                name={'organizationUnits'}
                                addToList={addOrganizationUnitsHandler}
                                dialogTitle='Dodaj nową jednostkę organizacyjną'
                                label='Jednostka organizacyjna'
                                multiple
                                options={organizationUnits}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <CustomAutocomplete
                                name={'departmens'}
                                addToList={addDepartmentsHandler}
                                dialogTitle='Dodaj nowy dział'
                                label='Dział'
                                multiple
                                options={departments}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerElement
                                parseError={parseError}
                                name={`hireDate`}
                                inputProps={{ fullWidth: true }}
                                required
                                label={'Data Zatrudnienia'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteElement
                                parseError={parseError}
                                name={'contractType'}
                                required
                                fullWidth
                                label={'Rodzaj umowy'}
                                options={[
                                    {
                                        id: 'Umowa o pracę',
                                        label: 'Umowa o pracę',
                                    },
                                    {
                                        id: 'Umowa zlecenie',
                                        label: 'Umowa zlecenie',
                                    },
                                    {
                                        id: 'Umowa o dzieło',
                                        label: 'Umowa o dzieło',
                                    },
                                    { id: 'B2B', label: 'B2B' },
                                    { id: 'Staż', label: 'Staż' },
                                    { id: 'Praktyka', label: 'Praktyka ' },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Dane kontaktowe</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'email'}
                                required
                                fullWidth
                                label={'Email'}
                                type={'email'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                validation={phoneValidation}
                                name={'phoneNumber'}
                                fullWidth
                                label={'Numer telefonu'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                parseError={parseError}
                                validation={secondPhoneValidation}
                                name={'secondPhoneNumber'}
                                fullWidth
                                label={'Drugi numer telefonu'}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>
                                1. Sprzęt komputerowy z oprogramowaniem biurowym
                            </InputLabel>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <RadioButtonGroup
                                name={'computer'}
                                parseError={parseError}
                                required
                                helperText='*) konieczność uzykania akceptacji Członka Zarządu'
                                onChange={changeGroupEmailNameHandler}
                                options={[
                                    {
                                        id: 'pc',
                                        label: 'Stacjonarny',
                                    },
                                    {
                                        id: 'laptop',
                                        label: 'Przenośny *)',
                                    },
                                    {
                                        id: 'laptopDock',
                                        label: 'Przenośny z dock *)',
                                    },
                                ]}
                                row
                            />
                        </Grid>

                        <CustomRadioGroup
                            label={
                                '2. Stanowisko pracy z dostępem do sieci komputerowej Portu'
                            }
                            name={'airportNetwork'}
                        />
                        <CustomRadioGroup
                            label={
                                '3. Stanowisko pracy z dostępem do Internetu'
                            }
                            name={'internet'}
                        />
                        <CustomRadioGroup
                            label={
                                '4. Stanowisko pracy z dostępem do indywidualnej poczty elektronicznej'
                            }
                            name={'individualEmail'}
                        />
                        <Grid item xs={12}>
                            <InputLabel>
                                5. Stanowisko pracy z dostępem do grupowej
                                poczty elektronicznej
                            </InputLabel>
                            <Divider />
                        </Grid>

                        <Grid item xs={5}>
                            <RadioButtonGroup
                                name={'groupEmail'}
                                parseError={parseError}
                                required
                                onChange={changeGroupEmailNameHandler}
                                options={[
                                    {
                                        id: 'yes',
                                        label: 'Tak',
                                    },
                                    {
                                        id: 'na',
                                        label: 'Nie dotyczy',
                                    },
                                ]}
                                row
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextFieldElement
                                parseError={parseError}
                                name={'groupEmailName'}
                                fullWidth
                                disabled={groupEmailNameDisabled}
                                label={'Konto grupowe'}
                                type={'email'}
                            />
                        </Grid>

                        <CustomRadioGroup
                            label={
                                '6. Stanowisko pracy z dostępem do drukarki sieciowej'
                            }
                            name={'printer'}
                            onChange={changePrinterColorHandler}
                        />
                        {!printerColorDisabled && (
                            <Grid item xs={12}>
                                <RadioButtonGroup
                                    name={'printerColor'}
                                    parseError={parseError}
                                    required
                                    options={[
                                        {
                                            id: 'mono',
                                            label: 'Wydruk czarno-biały',
                                        },
                                        {
                                            id: 'color',
                                            label: 'Wydruk kolorowy',
                                        },
                                    ]}
                                    row
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            alignItems: 'center',
                        }}
                    >
                        <LoadingButton
                            loading={buttonLoading}
                            type={'submit'}
                            variant='contained'
                        >
                            Zapisz
                        </LoadingButton>
                    </Box>
                </FormContainer>
            </Box>
        )
    }
)

export default EditEmployeeModal
