import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const LinkRouter = props => {
    return <Link {...props} component={RouterLink} />
}

const breadcrumbNameMap = {
    '/app': 'View',
    '/app/pulpit': 'Pulpit',
    '/app/szkolenia-pracownikow': 'Szkolenia pracowników',
    '/app/lista-szkolen': 'Lista szkoleń',
    '/app/moje-szkolenia': 'Moje szkolenia',
    '/app/kandydaci': 'Kandydaci',
    '/app/pracownicy': 'Pracownicy',
    '/app/upowaznienia': 'Upoważnienia',
    '/app/rejestr-czynnosci-przetwarzania': 'Rejestr czynności przetwarzania',
    '/app/rejestr-incydentow': 'Rejestr incydentów',
    '/app/rejestr-kategorii-przetwarzania': 'Rejestr kategorii przetwarzania',
    '/app/rejestr-zadan': 'Rejestr żądań',
    '/app/polityka-bezpieczenstwa': 'Polityka bezpieczeństwa',
    '/app/polityka-informacyjna': 'Polityka infromacyjna',
    '/app/sprawdzenia-planowe': 'Sprawdzenia planowe',
    '/app/sprawozdania-z-audytu': 'Sprawozdania z audytu',
    '/app/uchwaly-i-zarzadzenia': 'Uchwały i zarządzenia',
    '/app/zgody': 'Zgody',
    '/app/ustawienia': 'Ustawienia',
    '/app/systemy': 'Systemy',
}

const BasicBreadcrumbs = () => {
    const location = useLocation()
    const pathnames = location.pathname.split('/').filter(x => x)

    return (
        <Breadcrumbs aria-label='breadcrumb'>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join('/')}`

                return last ? (
                    <Typography color='text.primary' key={to}>
                        {breadcrumbNameMap[to]}
                    </Typography>
                ) : (
                    <LinkRouter
                        underline='hover'
                        color='inherit'
                        to={to}
                        key={to}
                    >
                        {breadcrumbNameMap[to]}
                    </LinkRouter>
                )
            })}
        </Breadcrumbs>
    )
}

export default BasicBreadcrumbs
