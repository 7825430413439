import DataTable from '../../DataTable/DataTable'
import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal/Modal'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import Button from '@mui/material/Button/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import EditEmployeeModal from '../../Modals/EditEmployeeModal/EditEmployeeModal'
import AddEmployeeModal from '../../Modals/AddEmployeeModal/AddEmployeeModal'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import Snackbar from '../../Snackbar/Snackbar'
import { DELETE, GET } from '../../../Helpers/fetch'
import columns from './columns'

const EmployeeTable = () => {
    const [data, setData] = useState([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deleteDataLoading, setDeleteDataLoading] = useState(false)
    const [editedRow, setEditedRow] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [openFireEmployeeDialog, setOpenFireEmployeeDialog] = useState(false)
    const [fireEmployeeLoading, setFireEmployeeLoading] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [snackbarInfo, setSnackbarInfo] = useState({})
    const [actionsAnchor, setActionsAnchor] = useState(null)

    const actionsIsOpen = Boolean(actionsAnchor)

    const actionsClickHandler = (event, index) => {
        setActionsAnchor(event.currentTarget)
        setEditedRow(index)
    }
    const actionsCloseHandler = () => {
        setActionsAnchor(null)
    }
    const editHandler = () => {
        actionsCloseHandler()
        setOpenModal(true)
    }
    const fireHandler = () => {
        actionsCloseHandler()
        setFireEmployeeLoading(true)
        const documentId = data[editedRow]._id

        const fire = async () => {
            await DELETE('employee/fire/' + documentId)
        }
        fire()
            .then(() => {
                setRefreshData(!refreshData)
                setFireEmployeeLoading(false)
                setOpenFireEmployeeDialog(false)
            })
            .catch(err => {
                setFireEmployeeLoading(false)
                console.log(err)
            })
    }

    const addModalCloseHandler = refresh => {
        setOpenAddModal(false)
        if (refresh) setRefreshData(!refreshData)
    }

    const deleteHandler = () => {
        actionsCloseHandler()
        setDeleteDataLoading(true)
        const documentId = data[editedRow]._id
        const deleteData = async () => {
            await DELETE('/employee/delete/' + documentId)
        }
        deleteData()
            .then(() => {
                setRefreshData(!refreshData)
                setDeleteDataLoading(false)
                setOpenDeleteDialog(false)
            })
            .catch(err => {
                setDeleteDataLoading(false)
                console.error(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            const { data: Items } = await GET('employee/get')
            if (Items) {
                setData([...Items.data])
                setLoading(false)
            }
        }
        getData().catch(console.error)
        return () => {
            setData([])
        }
    }, [refreshData])

    const importedColumns = columns({
        actionsClickHandler,
        actionsAnchor,
        actionsCloseHandler,
        actionsIsOpen,
        editHandler,
        setOpenFireEmployeeDialog,
        setOpenDeleteDialog,
    })

    const options = {
        customToolbar: () => (
            <CustomToolbar onClick={() => setOpenAddModal(true)} />
        ),
    }

    return (
        <>
            {snackbarInfo.open && (
                <Snackbar
                    setSnackbarInfo={setSnackbarInfo}
                    isOpen={snackbarInfo.open}
                    type={snackbarInfo.type}
                >
                    {snackbarInfo.message}
                </Snackbar>
            )}
            <DataTable
                title={'Pracownicy'}
                data={data}
                passedOptions={options}
                columns={importedColumns}
                loading={loading}
            />
            {!loading && (
                <Modal open={openAddModal} onClose={addModalCloseHandler}>
                    <DialogContent>
                        <AddEmployeeModal
                            setSnackbarInfo={setSnackbarInfo}
                            close={addModalCloseHandler}
                        />
                    </DialogContent>
                </Modal>
            )}

            {!loading && (
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <DialogContent>
                        <EditEmployeeModal
                            data={data[editedRow]}
                            refreshData={() => setRefreshData(!refreshData)}
                            close={() => setOpenModal(false)}
                        />
                    </DialogContent>
                </Modal>
            )}

            <Dialog
                open={openFireEmployeeDialog}
                onClose={() => setOpenFireEmployeeDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno chcesz zwolnić dane tego pracownika?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie danych
                        pracownika oraz wycofanie jego upoważnień. Czy na pewno
                        chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenFireEmployeeDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={fireEmployeeLoading}
                        onClick={fireHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>
                    Czy na pewno usunąć dane tego pracownika?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potwierdzenie spowoduje całkowite usunięcie danych
                        pracownika. Czy na pewno chcesz to zrobić?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Nie
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        loading={deleteDataLoading}
                        onClick={deleteHandler}
                    >
                        Tak
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EmployeeTable
